<template>
  <div>
    <van-nav-bar title="News" left-arrow @click-left="goBack" />
    <div v-if="loading" class="loading">
      <van-loading />
    </div>
    <div v-else>
      <h2>{{ news.title }}</h2>
      <div class="news-content" v-html="formattedContent"></div>
    </div>

    <br /><br /><br /><br /><br />

    <TabBar />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import axios from '@/utils/request'; // custom axios instance
import TabBar from '@/components/TabBar.vue';

export default {
  components: {
    TabBar,
  },
  setup() {
    const route = useRoute();
    const news = ref({});
    const loading = ref(true);
    const router = useRouter(); // Import useRouter for navigation
    const walletAddress = sessionStorage.getItem('walletAddress'); // 从 localStorage 获取 JWT
    const jwtToken = sessionStorage.getItem('jwtToken'); // 从 localStorage 获取 JWT
    console.log('NewsPage walletAddress=====' + walletAddress);
    console.log('NewsPage jwtToken=====' + jwtToken);

    const loadNewsDetail = async () => {

      console.log("route.query.id:" + route.query.id); // 查看完整的响应对象


      try {
        if (!jwtToken || !walletAddress) {
          console.error('No JWT token found. Redirecting to login page.');
          router.push('/'); // 没有 token 时跳转到登录页面
          return;
        }

        const response = await axios.get(`/api/news/${route.query.id}`, {
          headers: {
            auth: 'Bearer ' + jwtToken, // 添加 Bearer 前缀
          },
          params: {
            userid: walletAddress,
          },
        });
        
        news.value = response.news || {};
        console.log("news.value:" + news.value.title); // 查看完整的响应对象


      } catch (error) {
        console.error('Error fetching news detail:', error);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      loadNewsDetail();
    });
    const goBack = () => {
      router.back(); // Navigate to the previous page
    };

    // 计算属性：将换行符替换为 <br>
    const formattedContent = computed(() => {
      return news.value.content ? news.value.content.replace(/\n/g, '<br>') : '';
    });

    return {
      news,
      loading,
      goBack,
      formattedContent,
    };
  },
};
</script>

<style scoped>
.news-content {
  padding: 16px;
  text-align: left;
  /* Ensure text is aligned left */
}

.news-content h2 {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  /* Ensure heading is aligned left */
}

.news-content p {
  margin: 12px 0;
  font-size: 16px;
  text-align: left;
  /* Ensure paragraphs are aligned left */
}

.news-content div {
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
  /* Ensure inner div content is aligned left */
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
</style>
