<template>
    <van-tabbar v-model="active" active-color="#42b983">

    <router-link to="/rooms" class="tabbar-link">
      <van-tabbar-item icon="chat-o">Guichat</van-tabbar-item>
    </router-link>

    <router-link to="/friends" class="tabbar-link">
      <van-tabbar-item icon="friends-o">Contacts</van-tabbar-item>
    </router-link>

    <router-link to="/news/list" class="tabbar-link">
      <van-tabbar-item icon="newspaper-o">News</van-tabbar-item>
    </router-link>

    <router-link to="/me" class="tabbar-link">
      <van-tabbar-item icon="user-o">Me</van-tabbar-item>
    </router-link>

  </van-tabbar>
</template>




<script>
import { Tabbar, TabbarItem } from 'vant';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'TabBar',
  components: {
    'van-tabbar': Tabbar,
    'van-tabbar-item': TabbarItem,
  },
  setup() {
    const active = ref(0);
    const route = useRoute();

    // 映射路径到 tabbar 索引
    const pathToIndex = {
      '/rooms': 0,
      '/friends': 1,
      '/find': 2,
      '/me': 3,
    };

    // 监听路由变化，更新 active 值
    watch(
      () => route.path,
      (newPath) => {
        active.value = pathToIndex[newPath] ?? 0; // 如果路径不存在则默认第一个
      },
      { immediate: true } // 立即执行一次，以在组件加载时初始化值
    );

    return {
      active,
    };
  },
};
</script>



<style scoped>
.van-tabbar {
  position: fixed; /* 确保它固定在底部 */
  bottom: 0; /* 距离底部为0 */
  width: 100%; /* 确保宽度为100% */
  background-color: #fff; /* 设置背景色以确保可见 */
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1); /* 可选：添加阴影效果 */
}



/* 确保 TabBar 项目横向排列 */
.tabbar-link {
  display: flex; /* 使其支持 Flexbox */
  justify-content: center; /* 居中对齐 */
  align-items: center; /*垂直居中对齐*/
  flex: 1; /* 让每个 tabbar 项目平分空间 */
}
</style>
