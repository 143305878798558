import { createRouter, createWebHashHistory } from 'vue-router'; // 引入 createWebHashHistory
import AboutPage from '../views/AboutPage.vue';
import LoginPage from '../views/LoginPage.vue'; 
import MePage from '../views/MePage.vue'; 
import RoomsPage from '../views/RoomsPage.vue'; 
import FriendsPage from '../views/FriendsPage.vue'; 
import ChatPage from '../views/ChatPage.vue'; 
import FriendAddPage from '../views/FriendAddPage.vue'; 
import FindPage from '../views/FindPage.vue'; 
import NewsListPage from '../views/NewsListPage.vue'; 
import NewsDetailPage from '../views/NewsDetailPage.vue'; 
// import NewsAddPage from '../views/NewsAddPage.vue'; 
// import NewsUpdatePage from '../views/NewsUpdatePage.vue'; 

const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/rooms',
    name: 'RoomsPage',
    component: RoomsPage,
  },
  {
    path: '/friends',
    name: 'FriendsPage',
    component: FriendsPage,
  },

  {
    path: '/friends/add',
    name: 'FriendAddPage',
    component: FriendAddPage,
  },
  {
    path: '/chat',
    name: 'ChatPage',
    component: ChatPage,
  },
  {
    path: '/news/list',
    name: 'NewsListPage',
    component: NewsListPage,
  },
  {
    path: '/news/detail',
    name: 'NewsDetailPage',
    component: NewsDetailPage,
  },
  // {
  //   path: '/news/add',
  //   name: 'NewsAddPage',
  //   component: NewsAddPage,
  // },
  // {
  //   path: '/news/update',
  //   name: 'NewsUpdatePage',
  //   component: NewsUpdatePage,
  // },

////////////////find////////////////////
  {
    path: '/find',
    name: 'FindPage',
    component: FindPage,
  },

////////////////me/////////////////  
{
  path: '/me',
  name: 'MePage',
  component: MePage,
},
{
  path: '/about',
  name: 'AboutPage',
  component: AboutPage,
},



];

const router = createRouter({
  history: createWebHashHistory(), // 使用 createWebHashHistory
  routes
});

export default router;
