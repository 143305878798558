<template>
  <div>
    <!-- 顶部导航 -->
    <van-nav-bar title="Add friends" left-arrow @click-left="goBack" />

    <!-- 添加好友表单 -->
    <van-cell-group>
      <van-field v-model="friend_userid" label="wallet address" placeholder="Please enter your friend's wallet address" maxlength="50" clearable />
    </van-cell-group>

    <div class="button-container">
      <van-button type="primary" @click="submitFriendRequest">Submit</van-button>
    </div>
  </div>
  <TabBar />

</template>

<script>
import TabBar from '@/components/TabBar.vue'; // 确保路径正确
import { ref } from 'vue';
import axios from '@/utils/request'; // 自定义的 axios 实例
import { useRouter } from 'vue-router'; // 导入 useRouter
import { showToast } from 'vant'; // Vant 的 Toast 提示组件
//SP1QMZ9X8G3NG2A8QY4P7E88YEYC5FYAVB4T6CN9Q
//SP2DFS10R4C2RSX9R73SVM3ERE9N3H8S4S13YA8C9
//SPJP03A710G4G9QV2J4BVEETCC6W57CHTVMM82JP
//SP24PRWMGPXCZS7GNGD2AE2QNHW8VFTV7WSB17GG4

export default {
  components: {
    TabBar, // 确保 TabBar 组件被注册
  },
  setup() {
    const friend_userid = ref('');
    const router = useRouter();
    const walletAddress = sessionStorage.getItem('walletAddress');

    const goBack = () => {
      window.history.back();
    };

    const submitFriendRequest = async () => {
      if (!friend_userid.value.trim()) {
        // console.log('Please enter wallet address!');
        showToast('Please enter wallet address!');
        return;
      }

      try {
        const response = await axios.post('/api/friends', {
          userid: walletAddress,
          friend_userid: friend_userid.value.trim()
        });
        console.log(response.data); // 处理返回的数据
        showToast('Friend added successfully!');
        // console.log('Friend added successfully!', friend_userid.value.trim());
        // router.push({ path: `/chat`, query: { friend_userid: friend_userid.value.trim(),type:'friend',pagename:'friends' } });
        router.push({ path: `/friends`});

      } catch (error) {
        showToast('Friend does not exist!');
        console.error('Failed to add friend!', error);
      }
    };

    return {
      friend_userid,
      goBack,
      submitFriendRequest,
    };
  },
};
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: center; /* 水平居中 */
  margin: 20px 0; /* 可选：为按钮添加上下外边距 */
}

.van-button {
  width: auto; /* 确保按钮宽度自适应内容 */
}
</style>
