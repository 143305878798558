<template>
  <van-nav-bar :title="formattedTitle" />
  <!-- 房间列表 -->
  <van-list v-model:loading="loading" :finished="finished" finished-text="no news" @load="loadNews">
    <van-cell v-for="news in news" :key="news.id" :title="'# '+news.title" :label="'...' + news.timestamp" clickable
      @click="goToNewsDetail(news.id)" class="news-cell">
    </van-cell>
  </van-list>

  <TabBar />
</template>

<script>
import TabBar from '@/components/TabBar.vue'; // 确保路径正确
import { ref, computed, onMounted } from 'vue';
import axios from '@/utils/request'; // 自定义的 axios 实例
import { useRouter } from 'vue-router'; // 导入 useRouter

export default {
  components: {
    TabBar, // 确保 TabBar 组件被注册
  },
  setup() {
    const user = ref({});
    const news = ref([]);
    const finished = ref(false); // 控制列表是否完成加载
    const router = useRouter(); // 获取路由实例
    const walletAddress = sessionStorage.getItem('walletAddress'); // 从 localStorage 获取 JWT
    const jwtToken = sessionStorage.getItem('jwtToken'); // 从 localStorage 获取 JWT
    console.log('NewsPage walletAddress=====' + walletAddress);
    console.log('NewsPage jwtToken=====' + jwtToken);

    const formattedTitle = computed(() => {

      return 'News';
    });

    const loadNews = async () => {
      try {
        if (!jwtToken || !walletAddress) {
          console.error('No JWT token found. Redirecting to login page.');
          router.push('/'); // 没有 token 时跳转到登录页面
          return;
        }

        const response = await axios.get('/api/news', {
          headers: {
            auth: 'Bearer ' + jwtToken, // 添加 Bearer 前缀
          },
          params: {
            userid: walletAddress,
          },
        });
        console.log("Response received:", response); // 查看完整的响应对象

        news.value = response.news || []; // 确保即使没有 news 也能正常处理
        finished.value = news.value.length === 0; // 检查是否还有更多房间
      } catch (error) {
        router.push('/'); // 令牌无效或已过期时跳转到登录页面
        console.log("error:", error); // 查看完整的响应对象

      }
    };

    const goToNewsDetail = (id) => {
      router.push({
        path: '/news/detail',
        query: {
          id: id,
        },
      });
    };

    onMounted(() => {
      loadNews(); // 调用 loadNews
    });

    return {
      formattedTitle,
      user,
      news,
      finished,
      loadNews,
      goToNewsDetail,
    };
  },
};
</script>

<style scoped>
.news-cell {
  text-align: left;
  /* 使文字靠左对齐 */
  padding-left: 10px;
  /* 添加左侧内边距 */
}

.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8;
  /* 背景色可自定义 */
}

.news-logo {
  width: 50px;
  /* 调整房间图标的大小 */
  height: 50px;
  /* 调整房间图标的大小 */
  margin-right: 10px;
  /* Logo 右侧添加 10px 的间距 */
}
</style>
