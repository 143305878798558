<template>
  <van-nav-bar title="Guichat" />
  <br /><br />
  <div class="content">
    <p>Using AES256 encryption protocol</p>
    <van-button type="primary" @click="login" class="payment-button">Login</van-button>
  </div>

  <div style="text-align: center;">
    <div style="display: inline-block; text-align: left;">
      <p>1. Install <a href="https://leather.io/install-extension" target="_blank" rel="noopener noreferrer">Leather Wallet</a> and log in</p>
      <p>2. Add friend wallet address</p>
      <p>3. Let's chat about Web3! </p>
    </div>
  </div>


  <TabBar />
</template>

<script>
import { ref, onMounted } from 'vue';
import { Button, NavBar } from 'vant'; // 引入 Vant 组件
import { useRouter } from 'vue-router';
import axios from '@/utils/request'; // 自定义的 axios 实例
import { showToast } from 'vant'; // Vant 的 Toast 提示组件
import TabBar from '@/components/TabBar.vue';

export default {
  components: {
    'van-button': Button,
    'van-nav-bar': NavBar,
    TabBar, // 注册 TabBar 组件
  },
  setup() {
    const balanceStatus = ref('Balance Status'); // 余额状态
    const router = useRouter(); // 路由实例

    // 检测 Leather 钱包并连接
    const login = async () => {
      try {
        if (typeof window.StacksProvider !== 'undefined') {
          console.log('Leather Wallet detected');

          const response = await window.LeatherProvider?.request("getAddresses");
          // console.log("Addresses:", response.result.addresses);
          //获取stx地址
          const usersNativeSegwitAddress = response.result.addresses.find(address => address.symbol === 'STX');
          console.log("Addresses:", usersNativeSegwitAddress);
          if (usersNativeSegwitAddress) {
            const selectedAddress = usersNativeSegwitAddress.address;
            console.log("selectedAddress:", selectedAddress);

          const signResponse = await axios.post('/api/auth/sign', { userid: selectedAddress });
          console.log('Login jwtToken=====' + signResponse.jwtToken);
          sessionStorage.setItem('walletAddress', selectedAddress);
          sessionStorage.setItem('jwtToken', signResponse.jwtToken);
          router.push({ name: 'RoomsPage' });
          showToast('Login successful!');



          } else {
            console.log('STX address not found.');
          }

        } else {
          showToast('Please install Leather Wallet');
        }
      } catch (error) {
        console.error('Error during wallet interaction:', error);
        balanceStatus.value = 'Error occurred';
      }
    };

    // 页面加载时清理 sessionStorage
    onMounted(() => {
      console.log('Clearing sessionStorage on mounted');
      sessionStorage.clear();
    });

    return {
      balanceStatus,
      login, // 返回 login 方法供模板调用
    };
  },
};
</script>


<style scoped>
/* 样式保持不变 */
</style>
