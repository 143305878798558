import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';

// 创建 axios 实例
const instance = axios.create({
    // baseURL: 'http://localhost:3000', // //开发模式
  // baseURL: 'http://192.168.1.102:3000', // //生产模式本地
  baseURL: 'https://api.guichat.xyz', // //生产模式
  timeout: 10000, // 请求超时设置
});

instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = sessionStorage.getItem('jwtToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // 将 JWT 添加到 Authorization 头
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data; // 直接返回响应数据
  },
  (error) => {
    return Promise.reject(error); // 返回错误信息
  }
);

// 导出 axios 实例
export default instance;
