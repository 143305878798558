<template>
  <van-nav-bar :title="formattedTitle" />
  <!-- 房间列表 -->
  <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多房间" @load="loadRooms">
    <van-cell v-for="room in rooms" :key="room.roomid" :title="room.roomname"
      :label="room.latest_message.length > 20 ? room.latest_message.substring(0, 0) + '' + room.timestamp : room.latest_message + room.timestamp"
      clickable @click="goToChat(room.roomid)" class="room-cell">
      <template #icon>
        <img :src="require(`@/assets/room/${room.logo}.png`)" alt="" class="room-logo" width="40" height="40" />
      </template>
    </van-cell>
  </van-list>

  <TabBar />
</template>

<script>
import TabBar from '@/components/TabBar.vue'; // 确保路径正确
import { ref,computed,onMounted } from 'vue';
import axios from '@/utils/request'; // 自定义的 axios 实例
import { useRouter } from 'vue-router'; // 导入 useRouter

export default {
  components: {
    TabBar, // 确保 TabBar 组件被注册
  },
  setup() {
    const user = ref({});
    const rooms = ref([]);
    const finished = ref(false); // 控制列表是否完成加载
    const router = useRouter(); // 获取路由实例
    const walletAddress = sessionStorage.getItem('walletAddress'); // 从 localStorage 获取 JWT
    const jwtToken = sessionStorage.getItem('jwtToken'); // 从 localStorage 获取 JWT
    console.log('RoomsPage walletAddress====='+walletAddress);
    console.log('RoomsPage jwtToken====='+jwtToken);

    const formattedTitle = computed(() => {
      // 确保 walletAddress.value 有值，并且是一个字符串
      const address = walletAddress || '';
      const firstPart = address.slice(0, 6); // 获取前四位
      const lastPart = address.slice(-6);  // 获取后四位
      return `${firstPart}...${lastPart}`;
    });

    const loadRooms = async () => {
      try {
        if (!jwtToken || !walletAddress) {
          console.error('No JWT token found. Redirecting to login page.');
          router.push('/'); // 没有 token 时跳转到登录页面
          return;
        }

        const response = await axios.get(`/api/rooms`, {
          headers: {
            auth: 'Bearer ' + jwtToken, // 添加 Bearer 前缀
          },
          params: {
            userid: walletAddress, // 确保userid作为查询参数发送
          },
        });
        console.log("Response received:", response); // 查看完整的响应对象

        rooms.value = response.rooms || []; // 确保即使没有 rooms 也能正常处理
        finished.value = rooms.value.length === 0; // 检查是否还有更多房间
      } catch (error) {
        router.push('/'); // 令牌无效或已过期时跳转到登录页面
      }
    };

    const goToChat = (roomid) => {
      router.push({
        path: '/chat',
        query: {
          roomid: roomid,
          type: 'friend',
          pagename: 'rooms',
        },
      });
    };

    onMounted(() => {
      loadRooms(); // 调用 loadRooms
    });

    return {
      formattedTitle,
      user,
      rooms,
      finished,
      loadRooms,
      goToChat,
    };
  },
};
</script>

<style scoped>
.room-cell {
  text-align: left;
  /* 使文字靠左对齐 */
  padding-left: 10px;
  /* 添加左侧内边距 */
}

.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8;
  /* 背景色可自定义 */
}

.room-logo {
  width: 50px;
  /* 调整房间图标的大小 */
  height: 50px;
  /* 调整房间图标的大小 */
  margin-right: 10px;
  /* Logo 右侧添加 10px 的间距 */
}
</style>
