<template>
  <!-- 顶部导航 -->
  <van-nav-bar title="Contacts" right-text="+" @click-right="addFriend" />

  <!-- 好友列表 -->
  <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多好友了" @load="loadFriends">
    <van-cell v-for="friend in friends" :key="friend.userid"
      :title="friend.userid.length > 12 ? friend.userid.substring(0, 6) + '...' + friend.userid.substring(friend.userid.length - 6) : friend.userid"
      clickable @click="goToChat(friend.userid)" class="friend-cell">
      <template #icon>
        <img :src="require(`@/assets/user/${friend.logo}.png`)" alt="" class="friend-logo" width="40" height="40" />
      </template>
    </van-cell>
  </van-list>

  <TabBar />
</template>

<script>
import TabBar from '@/components/TabBar.vue'; // 确保路径正确
import { ref, onMounted } from 'vue';
import axios from '@/utils/request'; // 自定义的 axios 实例
import { useRouter } from 'vue-router'; // 导入 useRouter

export default {
  components: {
    TabBar, // 确保 TabBar 组件被注册
  },
  setup() {
    const friends = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const router = useRouter(); // 获取路由实例
    const walletAddress = sessionStorage.getItem('walletAddress');
    const jwtToken = sessionStorage.getItem('jwtToken'); // 从 localStorage 获取 JWT
    console.log('FriendsPage walletAddress====='+walletAddress);
    console.log('FriendsPage jwtToken====='+jwtToken);

    const loadFriends = async () => {
      if (loading.value) return;
      loading.value = true;

      try {
        if (!jwtToken || !walletAddress) {
          console.error('No JWT token found. Redirecting to login page.');
          router.push('/'); // 没有 token 时跳转到登录页面
          return;
        }

        const response = await axios.get('/api/friends', {
          headers: {
            auth: 'Bearer ' + jwtToken, // 添加 Bearer 前缀
          },          
          params: {
            userid: walletAddress,
          },
        });

        console.log("Response received:", response); // 查看完整的响应对象

        // 处理好友数据
        friends.value = response.friends;
        finished.value = friends.value.length === 0; // 检查是否还有更多好友

      } catch (error) {
        router.push('/'); // 令牌无效或已过期时跳转到登录页面
      } finally {
        loading.value = false; // 确保在结束时关闭加载状态
      }
    };

    const goBack = () => {
      // 返回逻辑，例如历史返回
      window.history.back();
    };

    const addFriend = () => {
      // 处理添加好友的逻辑
      console.log('添加好友');
      router.push({
        path: '/friends/add',
      });
    };

    const goToChat = (friendId) => {
      // 跳转到聊天页面
      router.push({
        path: '/chat',
        query: {
          userid: walletAddress,
          friend_userid: friendId,
          type: 'friend',
          pagename: 'friends' // 传递的参数 type
        }
      });
    };

    onMounted(loadFriends);

    return {
      friends,
      loading,
      finished,
      goBack,
      addFriend,
      goToChat,
    };
  },
};
</script>

<style scoped>
.friend-cell {
  text-align: left;
  /* 使文字靠左对齐 */
  padding-left: 10px;
  /* 添加左侧内边距 */
}

.friend-logo {
  width: 40px;
  /* Logo 的宽度 */
  height: 40px;
  /* Logo 的高度 */
  margin-right: 10px;
  /* Logo 右侧添加 10px 的间距 */
}
</style>
