<template>
  <van-page>
    <van-nav-bar :title="roomname" left-arrow @click-left="goBack" />
    <div class="messages" ref="messagesContainer">

      <div v-for="(message, index) in messages" :key="index">

        <template v-if="message.nickname !== walletAddress">
          <div class="message message-left">
            <small>
              <div style="text-align: left;">
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/user/5.png" alt="icon" style="width: 20px; margin-left: 5px;">
                  <strong>{{ formatNickname(message.nickname) }}</strong>,{{ message.time }}
                </div>
                <p style="text-align: left;">{{ message.content }}</p>
              </div>
            </small>
          </div>
        </template>

        <template v-else>
          <div class="message message-right">
            <small>
              <div style="text-align: right;">
                <div style="display: flex; align-items: center; justify-content: flex-end;">
                  <strong>{{ formatNickname(message.nickname) }},</strong> {{ message.time }}
                  <img src="@/assets/user/6.png" alt="icon" style="width: 20px; margin-left: 5px;">
                </div>
                <p style="text-align: right;">{{ message.content }}</p>
              </div>
            </small>
          </div>
        </template>
      </div>

    </div>



    <div class="input-container">
      <textarea v-model="newMessage" placeholder="Hi... Ctrl+Enter" rows="4" class="input-field"
        @keydown="handleKeydown"></textarea>
      <van-button type="primary" @click="sendMessageInput">Send</van-button>
    </div>
  </van-page>
</template>

<script>
import { ref, nextTick, onMounted } from 'vue';
import { io } from 'socket.io-client';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import axios from '@/utils/request';

export default {
  setup() {
    const newMessage = ref('');
    const messages = ref([]);
    const messagesContainer = ref(null);
    // const socket = io('http://localhost:3000'); // 开发模式
    // const socket = io('http://192.168.1.105:3000'); // 生产模式本地
    const socket = io('https://api.guichat.xyz', {
      path: '/socket.io/', // 确保与服务端一致
      transports: ['websocket', 'polling'], // 确保允许回退到轮询
    });

    const route = useRoute();
    const walletAddress = sessionStorage.getItem('walletAddress');
    const roomname = ref('');
    let nickname = '';
    const { pagename, type } = route.query;
    let friend_userid, roomid;

    if (pagename === 'friends') {
      friend_userid = route.query.friend_userid;
    } else {
      roomid = route.query.roomid;
    }

    const url = pagename === 'rooms' ? '/api/chat/room' : '/api/chat/friend';

    console.log('url============' + url);

    const loadChat = async () => {
      try {
        const response = await axios.get(url, {
          params: {
            userid: walletAddress,
            type,
            pagename,
            ...(pagename === 'rooms' ? { roomid } : { friend_userid })
          }
        });
        roomname.value = response.roomname;
        nickname = response.nickname;

        if (pagename === 'friends') {
          roomid = response.roomid;
        } else {
          friend_userid = response.friend_userid;
        }

      } catch (error) {
        console.error('获取失败', error);
      }
    };

    const goBack = () => {
      socket.disconnect();
      window.history.back();
    };

    const storeMessageInSessionStorage = (message) => {
      const existingMessages = JSON.parse(sessionStorage.getItem(roomid)) || [];
      existingMessages.push(message);
      sessionStorage.setItem(roomid, JSON.stringify(existingMessages));
    };

    const getChatRoomMessages = () => {
      return JSON.parse(sessionStorage.getItem(roomid)) || [];
    };

    const renderMessages = () => {
      const messageList = getChatRoomMessages();
      messages.value = messageList.map(encryptedMessage => {
        const decryptedMessage = decrypt(encryptedMessage, roomid);
        const messageObject = JSON.parse(decryptedMessage);
        return {
          time: messageObject.time,
          nickname: messageObject.nickname,
          content: messageObject.content,
        };
      });

      nextTick(() => {
        if (messagesContainer.value) {
          messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
        }
      });
    };

    const encrypt = (plaintext, key) => {
      if (!key) {
        console.error("Encryption key is undefined or null");
        return;
      }
      return CryptoJS.AES.encrypt(plaintext, key).toString();
    };

    const decrypt = (ciphertext, key) => {
      if (!key) {
        console.error("Decryption key is undefined or null");
        return;
      }
      const bytes = CryptoJS.AES.decrypt(ciphertext, key);
      return bytes.toString(CryptoJS.enc.Utf8);
    };

    socket.on('disconnect', () => {
      console.log('Disconnected from the server.');
      sessionStorage.removeItem(walletAddress);
    });

    socket.on('message', (data) => {
      const jsonData = JSON.parse(data);
      const { type: messageType, roomid: broadcastRoomId } = jsonData;
      // console.log('count:', jsonData);

      if (broadcastRoomId === roomid) {
        if (messageType === 'USER_JOIN') {
          jsonData.hist?.forEach(message => {
            if (message.content) {
              storeMessageInSessionStorage(message.content);
            }
          });
        } else if (messageType === 'USER_INPUT') {
          storeMessageInSessionStorage(jsonData.content);
        }
        renderMessages();
      }
    });

    const handleKeydown = (event) => {
      if (event.key === 'Enter' && event.ctrlKey) {
        event.preventDefault(); // 防止换行
        sendMessageInput();
      }
    };

    const sendMessageInput = () => {
      if (newMessage.value.trim()) {
        sendMessage('USER_INPUT', roomid, roomname.value, nickname, newMessage.value);
        newMessage.value = '';
      }
    };

    const sendMessage = (type, roomid, roomname, nickname, content) => {
      const time = new Date().toLocaleString();
      const plaintext = JSON.stringify({ time, nickname, content });
      const encrypted = encrypt(plaintext, roomid);
      socket.emit('message', {
        type,
        roomid,
        roomname,
        userid: walletAddress,
        nickname,
        content: encrypted,
        sharedKey: roomid
      });
    };

    onMounted(async () => {
      await loadChat();
      sessionStorage.removeItem(roomid);
      sendMessage('USER_JOIN', roomid, roomname.value, nickname, 'Hello!');
    });

    window.addEventListener('beforeunload', () => {
      socket.disconnect();
    });

    const formatNickname = (nickname) => {
      return nickname.length > 12
        ? nickname.substring(0, 6) + '...' + nickname.substring(nickname.length - 6)
        : nickname;
    };

    return {
      formatNickname, // 确保 formatNickname 被返回
      goBack,
      roomname,
      newMessage,
      messages,
      sendMessageInput,
      messagesContainer,
      handleKeydown,
      walletAddress
    };
  },
};
</script>

<style>
.messages {
  flex: 1;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  margin-bottom: 10px;
  padding: 10px;
}

.message {
  margin-bottom: 10px;
  max-width: 80%;
  display: flex;
  align-items: center;
}

.message-right {
  margin-left: auto;
  text-align: right;
  flex-direction: row-reverse;
  /* 将内容与图标反向排列 */
}

.message-left {
  margin-right: auto;
  text-align: left;
  flex-direction: row;
}

.message small {
  display: flex;
  align-items: center;
}


.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 5px;
  /* 将 padding 改为 20px */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.input-field {
  flex: 1;
  margin-right: 10px;
}

.van-page {
  padding: 16px;
}
</style>
