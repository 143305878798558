<template>
  <div>
    <!-- 顶部导航栏 -->
    <van-nav-bar title="Find"/>
    <!-- 用户信息展示 -->
    <div class="user-info">
      <van-cell center title="Tools" :border="false" class="text-left" />
    </div>
    <!-- 功能列表 -->
    <van-cell-group>
      <van-cell
      title="Leather Wallet"
      icon="link-o"
      is-link
      url="https://leather.io/"
      class="text-left"
    />
      <van-cell
      title="Gamma BNS"
      icon="link-o"
      is-link
      url="https://stacks.gamma.io/collections/bns-v2"
      class="text-left"
    />
    <van-cell
      title="BNS Hub"
      icon="link-o"
      is-link
      url="https://www.bnsv2.com/"
      class="text-left"
    />
    <van-cell
      title="News"
      icon="link-o"
      is-link
      url="/news/list"
      class="text-left"
    />


    </van-cell-group>
  </div>
  <TabBar />
</template>

<script>
import { ref } from 'vue';
import { showToast } from 'vant';
import TabBar from '@/components/TabBar.vue';
import axios from '@/utils/request';
import { useRouter } from 'vue-router'; // 导入 useRouter

export default {
  components: {
    TabBar,
  },
  setup() {
    const router = useRouter(); // 获取路由实例

    const walletAddress = sessionStorage.getItem('walletAddress') || ''; // 读取钱包地址
    const jwtToken = sessionStorage.getItem('jwtToken'); // 从 localStorage 获取 JWT

    // 用户头像图标
    const userIcon = ref(require('@/assets/user/1.png')); // 使用 require 加载图片

    if (!jwtToken || !walletAddress) {
      console.error('No JWT token found. Redirecting to login page.');
      router.push('/'); // 没有 token 时跳转到登录页面
      return;
    }


    // 左边按钮的点击事件
    const onClickLeft = () => {
      window.history.back();
    };

    // 点击Donation
    const onDonation = () => {
      window.location = 'https://www.lookoutward.xyz/#/pay';
    };

    const onDeleteChat = async () => {
      // 弹出确认对话框
      const confirmed = window.confirm('Are you sure you want to delete all chat messages?');

      // 如果用户未确认，直接返回
      if (!confirmed) return;

      const url = '/api/chat/delete';

      try {
        const response = await axios.post(url, {
          userid: walletAddress, // 将 walletAddress 作为请求参数传递
        });

        // 打印响应状态
        console.log(`response.status==${response.status}`);
        showToast('Delete successful!');

        // 根据状态码进行处理
        // if (response.status === 200) {
        //   showToast('Delete successful!');
        // } else {
        //   showToast('Delete failed, please try again.');
        // }
      } catch (error) {
        console.error('Error deleting chat:', error);
        showToast('Delete failed, please try again.');
      }
    };

    return {
      walletAddress,
      userIcon,
      onClickLeft,
      onDeleteChat,
      onDonation,
    };
  },
};
</script>

<style scoped>
/* 用户信息样式 */
.user-info {
  padding: 0px;
  margin-top: 0px;
  background-color: #f7f8fa;
}

/* 覆盖 van-cell 的默认样式，确保文本左对齐 */
.van-cell {
  text-align: left !important;
}

/* 确保导航栏标题左对齐 */
.nav-bar-left .van-nav-bar__title {
  text-align: left !important;
}

/* 调整 van-cell 内标题和标签的样式，确保左对齐 */
.text-left .van-cell__title,
.text-left .van-cell__label {
  text-align: left !important;
}
</style>
